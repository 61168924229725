.contact {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: row;
}

.contact_left {
    width: 50%;
    height: 760px;
}

.contact_left_content {
    margin-top: 30px;
    margin-left: 80px;
    display: flex;
    flex-direction: column;
    gap: 80px;
}

.contact_left_content_body {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.contact_left_content_body>div {
    display: flex;
    flex-direction: column;
    border-radius: 2px;
    background-color: var(--grey4);
    padding: 30px;
    /* padding-bottom: 40px; */
}

.contact_left_content_body>div h6 {
    font-family: Merriweather;
    font-size: 18px;
    font-weight: 700;
    line-height: 28px;
    text-align: left;
    color: var(--blue);
}

.contact_left_content_body>div img {
    width: 22px;
    height: 22px;
}


.contact_left_content_body>div>div {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
}

.contact_left_content_body>div:first-child>div {
    padding-bottom: 5px;
}

.contact_left_content_body>div:first-child {
    max-width: 560px;
    height: auto;
    /* height: 144px; */
}

.contact_left_content_body>div:first-child>h6 {
    padding-bottom: 5px;
}

.contact_left_content_body>div:last-child {
    max-width: 560px;
    height: auto;
    /* max-height: 200px; */
}

.contact_left_content_body>div:last-child>h6 {
    padding-bottom: 5px;
}

.contact_left_content_body>div:last-child>div {
    padding-bottom: 5px;
}

.contact_right {
    position: relative;
    width: 50%;
    height: 760px;
}

.contact_right>img {
    width: 100%;
    height: 760px;
}

.contact_right_lang {
    position: absolute;
    top: 3%;
    right: 5%;
}

@media (max-width: 1340px) {
    .contact {
        width: 100%;
        display: flex;
        flex-direction: row;
    }

    .contact_left {
        width: 100%;
        height: 760px;
    }

    .contact_right {
        width: 100%;
        height: 760px;
    }

    .contact_left_content_body {
        display: flex;
        flex-direction: column;
        gap: 10px;
        /* justify-content: center; */
        /* align-items: center; */
    }

    .contact_left_content {
        margin-top: 20px;
        margin-left: 20px;
    }
}

@media (max-width: 1200px) {
    .contact {
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    .contact_left {
        width: 100%;
        height: 760px;
    }

    .contact_right {
        width: 100%;
        height: auto;
    }

    .contact_right>img {
        width: 100%;
        height: 760px;
    }

    .contact_left_content_body {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 10px;
        justify-content: flex-start;
        align-items: self-start;
    }

    .contact_left_content_body>div {
        min-width: 100%;
        max-width: 100%;
        display: flex;
        flex-direction: column;
        border-radius: 2px;
        background-color: var(--grey4);
        padding: 40px;
    }

    .contact_left_content {
        margin-top: 20px;
        margin-left: 20px;
        margin-right: 20px;
    }
}

@media (max-width: 768px) {
    .contact_right>img {
        width: 100%;
        height: auto;
    }
}

@media (max-width: 360px) {
    .contact_left_content_body>div:first-child {
        max-width: 560px;
        height: auto;
    }

    .contact_left_content_body>div:last-child {
        max-width: 560px;
        height: auto;
    }

    .contact_left_content_body>div {
        padding: 10px;
    }


    .contact_left_content {
        margin-top: 10px;
        margin-left: 10px;
        margin-right: 10px;
    }

    .contact_left {
        width: 100%;
        height: 100%;
        padding-bottom: 20px;
    }

    .contact_right {
        width: 100%;
        height: 100%;
    }

    .contact_left_content_body p {
        font-size: 14px;
    }
    .contact_left_content_body>div img {
        width: 20px;
        height: 20px;
    }

    .contact_left_content_body>div h6 {
        font-size: 16px;
    }
}