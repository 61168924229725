footer {
    max-width: 100%;
    min-width: 100%;
    min-height: 472px;
    max-height: 100%;
    background-color: var(--blue);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 10px;
}

footer p,
svg {
    color: var(--white);
}

.footer_content {
    max-width: 1280px;
    /* height: 130px; */
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 40px;
    padding: 70px;
}

.footer_content_left {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    max-width: 550px;
    height: 120px;
    gap: 80px;

}

.footer_content_left>img {
    width: 130px;
    height: 120px;
}

.footer_content_left>h6 {
    max-width: 340px;
    height: 112px;
    font-family: Merriweather;
    font-size: 18px;
    font-weight: 700;
    line-height: 28px;
    text-align: left;
    color: var(--white);
}

.footer_content_right {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.footer_content_right>ul:first-child {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.footer_content_right>ul:first-child>li p {
    font-family: Inter;
    font-size: 14px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0.02em;
    text-align: left;
    text-transform: uppercase;
}

.footer_content_right>ul:last-child {
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.footer_content_right>ul:last-child>li {
    cursor: pointer;
}

/* .footer_content_right>ul:last-child>li p {
    font-family: Inter;
    font-size: 14px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0.02em;
    text-align: left;
} */

.footer_content_right {}

@media (max-width: 908px) {
    .footer_content {
        max-width: 768px;
        min-width: 100%;
        display: flex;
        flex-direction: column;
        /* justify-content: space-between; */
        /* align-items: center; */
        gap: 50px;
        padding: 0;
        margin: 0;
        padding-top: 70px;
        padding-left: 30px;
        padding-right: 30px;
    }

    .footer_content_left {
        display: flex;
        flex-direction: row;
        /* justify-content: space-between; */
        align-items: center;
        max-width: 700px;
        min-width: 100%;
        height: auto;
        min-height: 120px;

    }

    .footer_content_left>h6 {
        max-width: 400px;
        height: 112px;
        font-family: Merriweather;
        font-size: 18px;
        font-weight: 700;
        line-height: 28px;
        text-align: left;
        color: var(--white);
    }

    .footer_content_right {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        gap: 50px;
        padding: 0;
        margin: 0;
    }

    .footer_content_right>ul {
        padding: 0;
        margin: 0;
    }

    .footer_content_right>ul:first-child {
        width: 80%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        /* align-items: center; */
        /* gap: 70px; */
    }

    .footer_content_right>ul:last-child {
        width: 15%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

    }

    .footer_content_right>ul:last-child>li>svg {
        width: 24px;
        height: 24px;

    }
}


@media (max-width: 600px) {
    .footer_content {
        max-width: 100%;
        min-width: 100%;
        display: flex;
        flex-direction: column;
        /* justify-content: space-between; */
        /* align-items: center; */
        gap: 20px;
        padding: 0;
        margin: 0;
        padding-top: 20px;
        padding-left: 30px;
        padding-right: 30px;
    }

    .footer_content_left {
        display: flex;
        flex-direction: row;
        /* justify-content: space-between; */
        align-items: center;
        max-width: 700px;
        min-width: 100%;
        height: auto;
        min-height: 120px;

    }

    .footer_content_left>h6 {
        max-width: 400px;
        height: 112px;
        font-family: Merriweather;
        font-size: 18px;
        font-weight: 700;
        line-height: 28px;
        text-align: left;
        color: var(--white);
    }

    .footer_content_right {
        width: 100%;
        display: flex;
        flex-direction: column;
        /* justify-content: space-between; */
        /* align-items: center; */
        gap: 12px;
        padding: 0;
        margin: 0;
    }

    .footer_content_right>ul {
        padding: 0;
        margin: 0;
    }

    .footer_content_right>ul:first-child {
        width: 100%;
        display: flex;
        flex-direction: column;
        /* justify-content: space-between; */
        /* align-items: center; */
        /* gap: 70px; */
    }

    .footer_content_right>ul:last-child {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;

    }

    .footer_content_right>ul:last-child>li>svg {
        width: 24px;
        height: 24px;

    }
}

@media (max-width: 600px) {
    .footer_content_left {
        display: flex;
        flex-direction: column;
        gap: 30px;
        justify-content: flex-start;
        align-items: self-start;
        /* align-items: center;
        max-width: 700px;
        min-width: 100%;
        height: auto;
        min-height: 120px; */

    }

    .footer_content_right>ul:first-child>li p {
        font-family: Inter;
        font-size: 12px;
        font-weight: 600;
        line-height: 26px;
        letter-spacing: 0.02em;
        text-align: left;
        text-transform: uppercase;
    }

    .footer_content_left>h6 {
        max-width: 400px;
        height: 112px;
        font-family: Merriweather;
        font-size: 14px;
        font-weight: 700;
        line-height: 28px;
        text-align: left;
        color: var(--white);
    }
}