.main_layout{
    width: 100%;
}

.main_layout_body{
    display: flex;
    flex-direction: column;
    max-width: 1440px;
    height: auto;
    margin: 0 auto;
}

@media (max-width: 908px) {
    .main_layout_body{
        display: flex;
        flex-direction: column;
        max-width: 100%;
        /* min-width: 700px; */
        height: auto;
        /* margin: 0 auto; */
        margin: 0;
    }
}