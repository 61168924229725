.language-selector {
    position: relative;
    display: inline-block;
}

.dropdown-button {
    background-color: white;
    border: 1px solid white;
    border-radius: 2px;
    padding: 12px;
    font-size: 16px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 96px;
    height: 46px;
    position: relative;
}

.flag-icon {
    width: 20px;
    height: 20px;
    margin-right: 10px;
}

.dropdown-arrow {
    margin-left: auto;
    font-size: 12px;
    transition: transform 0.3s;
}

.dropdown-arrow.open {
    transform: rotate(180deg);
}

.dropdown-list {
    list-style: none;
    padding: 0;
    margin: 0;
    background-color: white;
    border: 1px solid var(--white);
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    z-index: 1;
    max-height: 150px;
    overflow-y: auto;
}

.dropdown-item {
    padding: 10px 20px;
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
}

.dropdown-item:hover {
    background-color: var(--grey4);
}

.selected {
    background-color: var(--white);
}

.dropdown-item img {
    margin-right: 10px;
}

.dropdown-button, .dropdown-item > p {
    font-family: Inter;
    font-size: 14px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0.02em;
    text-align: left;
    color: black;
}