.gold-button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    width: 100%;
    height: 100%;
    /* padding: 15px 20px 15px 20px; */
    gap: 14px;
    border-radius: 2px 0px 0px 0px;
    opacity: 0px;
    border: none;
    font-family: Inter;
    font-size: 14px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0.02em;
    text-align: left;
    background-color: var(--gold);
    color: var(--white);
    text-transform: uppercase;
}

.gold-button>svg {
    width: 20px;
    height: 18px;
    color: var(--white);
}

.gold-button:hover {
    background-color: var(--gold-hover);
}

.border-button{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    width: 100%;
    height: 100%;
    /* padding: 15px 20px 15px 20px; */
    gap: 14px;
    border-radius: 2px 0px 0px 0px;
    opacity: 0px;
    border: none;
    font-family: Inter;
    font-size: 14px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0.02em;
    text-align: left;
    border: 2px solid var(--gold);
    background-color: var(--blue);
    color: var(--white);
    text-transform: uppercase;
}

.border-button>svg {
    width: 20px;
    height: 18px;
    color: var(--white);
}

.border-button:hover {
    background-color: var(--blue-hover);
}



@media (max-width: 1000px) {
    .border-button{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        /* padding: 10px 15px 10px 15px; */
        gap: 5px;
        font-size: 12px;
        line-height: 20px;
    }
    .gold-button {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        font-size: 10px;
        line-height: 20px;
    }
}


@media (max-width: 1000px) {
    .border-button{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        /* padding: 10px 15px 10px 15px; */
        gap: 5px;
        font-size: 14px;
        line-height: 22px;
    }
    .gold-button {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        font-size: 14px;
        line-height: 22px;
    }
}