.insta_images {
    max-width: 1440px;
    height: 100%;
    overflow: hidden !important;
}

.insta_images_items {
    display: flex;
    flex-direction: row;
    transition: transform 0.3s ease;
}

.image {
    cursor: pointer;
    position: relative;
    width: 360px;
    height: 360px;
}

.image>img {
    display: block;
    width: 100%;
    height: auto;
}


.image_overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: .5s ease;
    background-color: #103F7BCC;
}

.image:hover .image_overlay {
    opacity: 1;
}

.image_text {
    color: white;
    font-size: 20px;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    text-align: center;
}

.image_text svg {
    width: 90px;
    height: 90px;
}

.image_title {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
}

.image_title>div>img {
    width: 60.06px;
    height: 40px;
    gap: 0px;
    opacity: 0px;

}

.image_title>div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
}

.image_title>div>h3 {
    font-family: Merriweather;
    font-size: 30px;
    font-weight: 700;
    line-height: 44px;
    text-align: center;
    
}

.image_title>div>p {
    font-family: Caveat;
    font-size: 24px;
    font-weight: 700;
    line-height: 30px;
    text-align: center;
    
}


@media (max-width: 1280px) {
    .image {
        width: 260px;
        height: 260px;
    }

}

@media (max-width: 1024px) {
    .image {
        width: 260px;
        height: 260px;
    }

}

@media (max-width: 960px) {
    .image {
        width: 240px;
        height: 240px;
    }
}

@media (max-width: 912px) {
    .image {
        width: 230px;
        height: 230px;
    }
}

@media (max-width: 853px) {
    .image {
        width: 280px;
        height: 280px;
    }
}

@media (max-width: 820px) {
    .image {
        width: 274px;
        height: 274px;
    }
}

@media (max-width: 768px) {
    .image {
        width: 384px;
        height: 384px;
    }
    .insta_images_items {
        display: grid;
        grid-template-columns: auto auto;
    }
    

}

@media (max-width: 540px) {
    .image {
        width: 270px;
        height: 270px;
    }
    .insta_images_items {
        display: grid;
        grid-template-columns: auto auto;
    }
    .image_title>div>h3 {
        font-size: 26px;
        line-height: 44px;
        
    }
    
    .image_title>div>p {
        font-size: 20px;
        line-height: 30px;
        
    }
}

@media (max-width: 480px) {
    .image {
        width: 240px;
        height: 240px;
    }
}

@media (max-width: 430px) {
    .image {
        width: 220px;
        height: 220px;
    }

}

@media (max-width: 414px) {
    .image {
        width: 210px;
        height: 210px;
    }

    .image_title>div>h3 {
        font-size: 22px;
        line-height: 44px;
        
    }
    
    .image_title>div>p {
        font-size: 18px;
        line-height: 30px;
        
    }

}

@media (max-width: 400px) {
    .image {
        width: 200px;
        height: 200px;
    }

}

@media (max-width: 375px) {
    .image {
        width: 188px;
        height: 188px;
    }

}

@media (max-width: 360px) {
    .image {
        width: 360px;
        height: 360px;
    }
    .insta_images_items {
        display: flex;
        flex-direction: column;
    }
    .image_title>div>h3 {
        font-size: 30px;
        line-height: 44px;
        
    }
    
    .image_title>div>p {
        font-size: 24px;
        line-height: 30px;
        
    }

}

@media (max-width: 344px) {
    .image {
        width: 360px;
        height: 360px;
    }
    .insta_images_items {
        display: flex;
        flex-direction: column;
    }
    .image_title>div>h3 {
        font-size: 30px;
        line-height: 44px;
        
    }
    
    .image_title>div>p {
        font-size: 24px;
        line-height: 30px;
        
    }

}


@media (max-width: 320px) {
    .image {
        width: 320px;
        height: 320px;
    }
    .insta_images_items {
        display: flex;
        flex-direction: column;
    }
    .image_title>div>h3 {
        font-size: 30px;
        line-height: 44px;
        
    }
    
    .image_title>div>p {
        font-size: 24px;
        line-height: 30px;
        
    }
}