.catalog {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    background-color: var(--grey4);
}

.catalog_content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
}

.catalog_content_menu {
    max-width: 1280px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 5px;
}

.catalog_content_menu_item {
    cursor: pointer;
    width: 314px;
    height: 58px;
    padding: 15px 20px 15px 20px;
    gap: 14px;
    border: 0px 0px 2px 0px;
    opacity: 0px;
    transition: .3s ease;
    border-bottom: 2px solid var(--grey2);

}

.catalog_content_menu_item>p {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-family: Merriweather;
    font-size: 18px;
    font-weight: 700;
    line-height: 28px;
    text-align: left;
    color: var(--blue);
}

.catalog_content_menu_item_active {
    border-bottom: 2px solid var(--blue);
}

.catalog_content_items {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 35px;
}



@media (max-width: 1340px) {
    .catalog_content_menu_item {
        width: 240px;
        height: 58px;
    }
}