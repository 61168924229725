/* About Box */
/* Start */
.about_box {
    max-width: 1300px;
    
}

.about_box_body{
    display: flex;
    flex-direction: row;
    margin: 100px;
}

.about_box_img {
    width: 30%;
}

.about_box_img>img {
    max-width: 260px;
    max-height: 260px;
    border-radius: 150px;
}

.about_box_content {
    width: 70%;
}

.about_box_content_wrap {
    max-width: 840px;
    display: flex;
    flex-direction: column;
    gap: 30px
}

.about_box_content_wrap>div:first-child {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.about_box_content_wrap>div:first-child>p {
    font-family: Caveat;
    font-size: 24px;
    font-weight: 700;
    line-height: 30px;
    text-align: left;
    color: var(--blue);
}

.about_box_content_wrap>div:first-child>h1 {
    font-family: Inter;
    font-size: 30px;
    font-weight: 600;
    line-height: 40px;
    text-align: left;
}

.about_box_content_wrap>div:last-child {
    display: flex;
    flex-direction: row;
    gap: 15px;
}

.about_box_content_wrap>div:last-child>p {
    width: 50%;
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    color: var(--grey1);
}

/* End */


/* Clients Box */
/* Start */

.client_box {
    width: 100%;
    height: 450px;
    background-color: var(--grey4);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.client_box_body {
    max-width: 1200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 40px;
}

.client_box_header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.client_box_header>h3 {
    width: 840px;
    height: 88px;
    font-family: Merriweather;
    font-size: 30px;
    font-weight: 700;
    line-height: 44px;
    text-align: center;
    color: var(--blue);

}

.client_box_content {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 20px;
}

.client_box_content>li {
    /* width: 205px;
    height: 120px; */
}

.client_box_content>li>img {
    /* width: 150px;
    height: 50px; */
}

/* End */

/* Info Box */
/* Start */

.info_box {
    display: flex;
    flex-direction: row;
    padding: 100px;
}

.info_box_left {
    max-width: 730px;
    /* height: 598px; */
    gap: 60px;
}

.info_box_left_header {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.info_box_left_header>p {
    max-width: 730px;
    height: 30px;
    gap: 0px;
    font-family: Caveat;
    font-size: 24px;
    font-weight: 700;
    line-height: 30px;
    text-align: left;
    color: var(--blue)
}

.info_box_left_header>h4 {
    font-family: Inter;
    font-size: 30px;
    font-weight: 600;
    line-height: 40px;
    text-align: left;

}

.info_box_left_content {
    display: flex;
    flex-direction: column;
    gap: 10px;
    max-width: 620px;
    /* height: 336px; */
    padding-top: 50px;
    padding-left: 100px;
}

.info_box_left_content>p {
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    color: var(--grey1);

}

.info_box_right {
    max-width: 440px;
    height: 529px;
    gap: 0px;
}

/* End */

/* Video Box */
/* Start */
.video_box {
    width: 100%;
    /* height: 414px; */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.video_box_content {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    max-width: 1280px;
    /* height: 410px; */

}

.video_box_left {
    max-width: 57%;
    height: 410px;
}

.video_box_left>img {
    height: 410px;
    object-fit: cover;
}

.video_box_right {
    max-width: 43%;
    height: 410px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: var(--blue);
}

.video_box_right>h4 {
    font-family: Inter;
    font-size: 30px;
    font-weight: 600;
    line-height: 40px;
    text-align: center;
    color: var(--white);
}

.video_box_right>p {
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    color: var(--white);
}

/* End */

@media (max-width: 1340px) {
    .about_box {

    }
    .about_box_body{
        margin: 30px;
    }
    .product_box {
        display: flex;
        flex-direction: column;
        padding-top: 50px;
    }

    .info_box {
        padding: 30px;
    }

    .info_box_right {
        max-width: 380px;
        height: 479px;
        gap: 0px;
    }

    .info_box_left_header>p {
        font-size: 24px;
        line-height: 20px;
        padding-bottom: 20px;
    }

    .info_box_left_header>h4 {
        font-size: 24px;
        line-height: 30px;
    }

    .info_box_left_content>p {
        font-size: 16px;
        line-height: 24px;
    }

    .video_box_content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .video_box_left {
        max-width: 100%;
        /* height: 410px; */
    }

    .video_box_left>img {
        height: 410px;
        object-fit: cover;
    }

    .video_box_right {
        max-width: 100%;
        /* height: 410px; */
        display: flex;
        flex-direction: column;
        justify-content: start;
        align-items: center;
        padding: 30px;
    }

    .video_box_right>h4 {
        font-size: 26px;
        line-height: 30px;
    }

    .video_box_right>p {
        font-size: 14px;
        line-height: 24px;
    }
}


@media (max-width: 1000px) {
    .about_box {
        max-width: 100%;
        display: flex;
        justify-content: center;
    }

    .about_box_body{
        max-width: 1000px;
        display: flex;
        flex-direction: row;
        gap: 30px;
    }

    .about_box_img>img {
        max-width: 200px;
        max-height: 200px;

    }

    .about_box_content_wrap {
        max-width: 460px;
        display: flex;
        flex-direction: column;
        gap: 20px
    }

    .about_box_content_wrap>div:last-child {
        display: flex;
        flex-direction: column;
        gap: 10px;
    }
    
    .about_box_content_wrap>div:last-child>p {
        width: 100%;
        font-family: Inter;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        text-align: left;
        color: var(--grey1);
    }

    .info_box {
        padding: 30px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        /* align-items: center; */
    }

    .info_box_right {
        max-width: 380px;
        height: 479px;

    }
    .info_box_left_header {
        display: flex;
        flex-direction: column;
        gap: 30px;
    }

    .info_box_left_header>p {
        font-size: 24px;
        line-height: 30px;
        padding-bottom: 10px;
    }

    .info_box_left_header>h4 {
        font-size: 26px;
        line-height: 34px;
    }
    .info_box_left_content {
        padding-top: 50px;
        padding-left: 0px;
    }
    .info_box_left_content>p {
        font-size: 16px;
        line-height: 24px;
    }

}


@media (max-width: 800px) {
    .about_box {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 20px;
    }

    .about_box_img>img {
        /* max-width: 200px;
        max-height: 200px; */

    }
}

@media (max-width: 670px) {
    .about_box {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 20px;
    }

    .about_box_img>img {
        /* max-width: 200px;
        max-height: 200px; */

    }

    .about_box_body{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: self-start;
        justify-content: center;
        margin: 0px;
        padding-left: 50px;
        padding-top: 50px;
        padding-bottom: 40px;
    }

    /* .about_box_content_wrap {
        max-width: 840px;
        display: flex;
        flex-direction: column;
        gap: 30px
    }
     */
    .about_box_content_wrap>div:first-child {
        max-width: 100%;
        min-width: 100%;
    }
    
    .about_box_content_wrap>div:first-child>p {
        max-width: 450px;
        min-width: 450px;
        font-size: 24px;
        font-weight: 700;
        line-height: 30px;
    }
    
    .about_box_content_wrap>div:first-child>h1 {
        max-width: 450px;
        min-width: 450px;
        font-size: 26px;
        font-weight: 600;
        line-height: 35px;
    }
    
    .about_box_content_wrap>div:last-child {
        max-width: 100%;
        min-width: 100%;
    }
    
    .about_box_content_wrap>div:last-child>p {
        max-width: 450px;
        min-width: 450px;
        height: auto;
        font-size: 14px;
        line-height: 20px;
    }
    
}


@media (max-width: 500px) {
    .about_box {

    }

    .about_box_img>img {

    }

    .about_box_body{
        padding-left: 50px;
        padding-top: 50px;
        padding-bottom: 40px;
    }

    
    .about_box_content_wrap>div:first-child>p {
        max-width: 350px;
        min-width: 350px;
        font-size: 24px;
        font-weight: 700;
        line-height: 30px;
    }
    
    .about_box_content_wrap>div:first-child>h1 {
        max-width: 350px;
        min-width: 350px;
        font-size: 26px;
        font-weight: 600;
        line-height: 35px;
    }

    .about_box_content_wrap>div:last-child>p {
        max-width: 350px;
        min-width: 350px;
        height: auto;
        font-size: 14px;
        line-height: 20px;
    }
    .info_box{
        display: flex;
        flex-direction: column;
        flex-flow: column-reverse;
    }
    .video_box{
        height: auto;
    }
    .video_box_content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .video_box_left {
        max-width: 100%;
        height: 250px;
    }

    .video_box_left>img {
        height: 250px;
        object-fit: cover;
    }

    .video_box_right {
        max-width: 100%;
        /* height: 410px; */
        display: flex;
        flex-direction: column;
        justify-content: start;
        align-items: center;
        padding: 30px;
    }

    .video_box_right>h4 {
        font-size: 26px;
        line-height: 30px;
    }

    .video_box_right>p {
        font-size: 14px;
    }
}


@media (max-width: 450px) {
    .about_box {

    }

    .about_box_img>img {

    }

    .about_box_body{
        padding-left: 50px;
        padding-top: 50px;
        padding-bottom: 40px;
    }

    
    .about_box_content_wrap>div:first-child>p {
        max-width: 300px;
        min-width: 300px;
        font-size: 24px;
        font-weight: 700;
        line-height: 30px;
    }
    
    .about_box_content_wrap>div:first-child>h1 {
        max-width: 300px;
        min-width: 300px;
        font-size: 26px;
        font-weight: 600;
        line-height: 35px;
    }

    .about_box_content_wrap>div:last-child>p {
        max-width: 300px;
        min-width: 300px;
        height: auto;
        font-size: 14px;
        line-height: 20px;
    }
    
}

@media (max-width: 400px) {
.info_box_right{
    max-width: 350px;
    height: auto !important;
}
}

@media (max-width: 360px) {
    .about_box {

    }

    .about_box_img>img {

    }

    .about_box_body{
        padding-left: 50px;
        padding-top: 50px;
        padding-bottom: 40px;
    }

    
    .about_box_content_wrap>div:first-child>p {
        max-width: 250px;
        min-width: 250px;
        font-size: 24px;
        font-weight: 700;
        line-height: 30px;
    }
    
    .about_box_content_wrap>div:first-child>h1 {
        max-width: 250px;
        min-width: 250px;
        font-size: 26px;
        font-weight: 600;
        line-height: 35px;
    }

    .about_box_content_wrap>div:last-child>p {
        max-width: 250px;
        min-width: 250px;
        height: auto;
        font-size: 14px;
        line-height: 20px;
    }

    .video_box_left {
        max-width: 100%;
        height: 200px;
    }

    .video_box_left>img {
        height: 200px;
        object-fit: cover;
    }
    
}