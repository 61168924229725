.detail {
    width: 100%;
    display: flex;
    flex-direction: column;
}

/* .detail p,
h1,
h2,
h3,
h4,
h5,
h6 {
    color: var(--white);
} */

.detail_header {
    width: 100%;
    display: flex;
    flex-direction: row;
}

.detail_left {
    width: 50%;
    background-color: var(--blue);
}

.detail_left_content {
    max-width: 560px;
    height: 748px;
    margin: 50px;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 100px;
}

.detail_left_body {}

.detail_left_body>h2 {
    font-family: Merriweather;
    font-size: 40px;
    font-weight: 700;
    line-height: 52px;
    text-align: left;
    color: var(--white);
}

.detail_left_body_summary {
    margin-top: 30px;
    margin-bottom: 40px;
    padding-left: 30px;
    border-left: 2px solid var(--gold);
}

.detail_left_body_summary>p {
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    color: var(--grey4);

}

.detail_left_body_summary>h6 {
    font-family: Merriweather;
    font-size: 18px;
    font-weight: 700;
    line-height: 28px;
    text-align: left;
    margin-top: 10px;
    margin-bottom: 10px;
    color: var(--white);
}

.detail_left_body_button {
    width: 50%;
    height: 40px;
}

.detail_right {
    position: relative;
    width: 50%;
}

.detail_right_lang {
    position: absolute;
    top: 3%;
    right: 10%;
}

.detail_right_image {
    width: 720px;
    height: 848px;
}

.detail_right_img {
    position: absolute;
    width: 120px;
    height: 182px;
    bottom: 10%;
    right: 10%;
}

@media (max-width: 1340px) {
    .detail_left_content {
        height: 650px;
        margin: 30px;
        margin-top: 10px;
        display: flex;
        flex-direction: column;
        gap: 30px;
    }
    
    .detail_left_body {}
    
    .detail_left_body>h2 {
        font-size: 25px;
        line-height: 35px;
    
    }
    
    .detail_left_body_summary {
        margin-top: 20px;
        margin-bottom: 30px;
        padding-left: 20px;
    }
    
    .detail_left_body_summary>p {
        font-size: 14px;
        line-height: 20px;
    
    }
    
    .detail_left_body_summary>h6 {
        font-size: 16px;
    }

    .detail_right_image {
        height: 700px;
    }
    
    .detail_left_body_button {
        width: 60%;
    }
}