.header {
    width: 100%;
    height: 670px;
    margin: 0 auto;
}

.header_content {
    position: relative;
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
}

.header_left_box {
    width: 72%;
    height: 100%;
    background-color: var(--blue);
}

.header_right_box {
    position: relative;
    width: 28%;
    height: 100%;
}

.header_right_box_lang {
    position: absolute;
    top: 3%;
    right: 5%;
}

.right_box_img {
    height: 670px;
    object-fit: cover;
}

.header_mid_img {
    position: absolute;
    width: 120px;
    height: 182px;
    gap: 0px;
    left: -14.5%;
    top: 35%;
}

.header_left_box_body {
    display: flex;
    flex-direction: column;
    max-width: 960px;
    height: 670px;
    padding: 20px 80px 80px 80px;
    gap: 10px;
}

.logo {
    width: 140px;
    height: 138.78px;
    top: 0.88px;
    gap: 0px;
}

.header_sub_content {
    display: flex;
    flex-direction: column;
    gap: 40px;
    max-width: 800px;
    height: 330px;
    margin-top: 100px;

}

.header_title {
    max-width: 790px;
    height: 174px;
    font-family: Merriweather;
    font-size: 44px;
    font-weight: 700;
    line-height: 58px;
    text-align: left;
    color: var(--white);
}

.header_sub_title {
    display: flex;
    flex-direction: row;
    gap: 20px;
    margin-top: 10px;
}

.header_sub_title>span {
    width: 2px;
    max-height: 48px;
    height: auto !important;
    background-color: var(--gold);
}

.header_sub_title>p {
    width: 590px;
    height: 48px;
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    color: var(--grey4);
}

.header_buttons {
    display: flex;
    flex-direction: row;
    gap: 5px;
}

.header_buttons>div {
    height: 50px;
}

.header_buttons>div:first-child {
    width: 245px;
}

.header_buttons>div:last-child {
    width: 240px;
}

@media (max-width: 1340px) {
    .header_left_box_body {
        padding: 10px 40px 40px 40px;
    }

    .header_title {
        max-width: 650px;
        height: auto;
        max-height: 150px;
        font-size: 35px;
        line-height: 40px;
    }

    .header_sub_title>p {
        max-width: 490px;
        max-height: 48px;
        font-size: 16px;
        line-height: 24px;
    }
}


@media (max-width: 1000px) {

    .header_sub_content {
        display: flex;
        flex-direction: column;
        gap: 15px;

    }

    .header_left_box_body {
        padding: 5px 30px 30px 30px;
    }

    .header_title {
        max-width: 500px;
        font-size: 28px;
        line-height: 40px;
    }

    .header_sub_title {
        margin-top: 10px;
    }

    .header_sub_title>p {
        max-width: 490px;
        max-height: 48px;
        font-size: 16px;
        line-height: 24px;
    }

    .header_sub_title>span {
        width: 2px;
        max-height: 48px;
        height: auto !important;
        background-color: var(--gold);
    }

    .header_buttons>div:first-child {
        width: 240px;
    }

    .header_buttons>div:last-child {
        width: 156px;
    }
}

@media (max-width: 768px) {
    .header {
        width: 100%;
        height: 863px;
        margin: 0 auto;
    }

    .header_content {
        position: relative;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
    }

    .header_left_box {
        width: 100%;
        height: 556px;
    }

    .header_left_box_body {
        display: flex;
        flex-direction: column;
        max-width: 700px;
        height: 476px;
        margin-top: 20px;
        gap: 10px;

    }

    .header_sub_content {
        display: flex;
        flex-direction: column;
        gap: 16px;
        max-width: 700px;
        height: 184px;
        margin-top: 50px;
    }

    .header_right_box {
        width: 100%;
        height: 28%;
    }

    .right_box_img {
        width: 100%;
        height: 278px;
        object-fit: cover;
    }

    .header_mid_img {
        position: absolute;
        width: 120px;
        height: 182px;
        left: 80%;
        top: 20%;
    }

    .header_title {
        max-width: 700px;
        height: auto;
        max-height: 120px;
        font-size: 28px;
        line-height: 40px;
    }

    .header_sub_title {
        margin-top: 10px;
    }

    .header_sub_title>p {
        max-width: 700px;
        height: auto;
        max-height: 48px;
        font-size: 16px;
        line-height: 24px;
    }

}

@media (max-width: 668px) {
    .header {
        width: 100%;
        height: auto !important;
        margin: 0 auto;
    }
    .header_mid_img {
        position: absolute;
        width: 120px;
        height: 182px;
        left: 75%;
        top: 20%;
    }
}




@media (max-width: 500px) {
    .header_mid_img {
        position: absolute;
        width: 100px;
        height: 160px;
        left: 75%;
        top: 20%;
    }

    .header_buttons {
        display: flex;
        flex-direction: column;
        gap: 5px;
    }

    .header_buttons>div {
        height: 50px;
    }

    .header_buttons>div:first-child {
        width: 100%;
    }

    .header_buttons>div:last-child {
        width: 100%;
    }

    .header_title {
        max-width: 700px;
        height: auto;
        max-height: 240px;
        font-size: 24px;
        line-height: 30px;
    }

    .header_sub_title {
        margin-top: 10px;
    }

    .header_sub_title>p {
        max-width: 700px;
        height: auto;
        max-height: 48px;
        font-size: 14px;
        line-height: 20px;
    }
    .header_right_box {
        width: 100%;
        height: 28%;
    }

    .header_left_box {
        width: 100%;
        height: 72%;
    }

    .header_left_box_body {
        display: flex;
        flex-direction: column;
        max-width: 700px;
        height: auto;
        margin-top: 20px;
        gap: 10px;

    }

    .header_sub_content {
        display: flex;
        flex-direction: column;
        gap: 16px;
        max-width: 700px;
        height: auto;
        margin-top: 50px;

    }


    .header_sub_title>span {
        display: none !important;
    }
}


@media (max-width: 405px) {
    .header_mid_img {
        position: absolute;
        width: 100px;
        height: 160px;
        left: 65%;
        top: 20%;
    }
}