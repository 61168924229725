.list_view {
    width: 100%;
    min-height: 450px;
    background-color: var(--grey4);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    /* overflow: scroll; */
}

.list_view_body {
    max-width: 100%;
    min-width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 40px;
    margin: 10px;
}

.list_view_header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.list_view_header>h3 {
    max-width: 840px;
    height: 88px;
    font-family: Merriweather;
    font-size: 30px;
    font-weight: 700;
    line-height: 44px;
    text-align: center;
    color: var(--blue);

}

.list_view_content {
    width: 1280px;
    /* height: 120px; */
    padding: 0;
    /* overflow: hidden; */
    /* display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 20px;
    
    height: 200px;
    margin: 10px; */
}

.list_view_content_item {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.list_view_content_item>img {
    max-width: 180px;
    max-height: 120px;
    object-fit: contain;
}



.image_list {
    max-width: 1440px;
    height: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    overflow: hidden;
}


.image_list svg {
    color: var(--white);
}

/* .image_list>div>img {
    width: 288px;
    height: 288px;
}

.image_list>div:nth-child(2) {
    position: relative;
}

.image_list>div:nth-child(2)>svg {
    position: absolute;
    width: 100px;
    height: 100px;
    top: 30%;
    left: 30%;
} */
.slick-dots li button:before {
    color: var(--grey2);
    /* Change to any color you like */
    font-size: 12px;
    /* You can adjust the size of the dots if needed */
}

/* Change the color of the active dot */
.slick-dots li.slick-active button:before {
    color: var(--blue);
    /* Change to the color you want for the active dot */
}

.slick-track {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    /* gap: 5px; */
}

.slick-prev {
    display: none !important;
}

.slick-next {
    display: none !important;
}

.image-item {
    cursor: pointer;
    position: relative;
    width: 188px;
    height: 188px;
}

.image-item_image {
    display: block;
    width: 100%;
    height: auto;
}

.image-item_overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: .5s ease;
    background-color: #103F7BCC;
}

.image-item:hover .image-item_overlay {
    opacity: 1;
}

.image-item_text {
    color: white;
    font-size: 20px;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    text-align: center;
}

.image-item_text svg {
    width: 90px;
    height: 90px;
}

@media (max-width: 1300px) {


    .list_view_content {
        width: 1100px;
        /* height: 120px; */
        padding: 0;
        /* padding-left: 50px; */
        /* padding-right: 50px; */
        /* overflow: hidden; */
        /* display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 20px;
        
        height: 200px;
        margin: 10px; */
    }

    .list_view_content_item {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .list_view_content_item>img {
        max-width: 160px;
        max-height: 94px;
        object-fit: contain;
    }
}

@media (max-width: 1024px) {
    .list_view_header>h3 {
        font-family: Merriweather;
        font-size: 26px;
        font-weight: 700;
        line-height: 38px;
        text-align: center;


    }


    .list_view_content {
        width: 900px;
        /* height: 120px; */
        padding: 0;
        /* padding-left: 50px; */
        /* padding-right: 50px; */
        /* overflow: hidden; */
        /* display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 20px;
        
        height: 200px;
        margin: 10px; */
    }

    .list_view_content_item {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .list_view_content_item>img {
        max-width: 160px;
        max-height: 94px;
        object-fit: contain;
    }
}


@media (max-width: 1000px) {
    .list_view_header>h3 {
        font-family: Merriweather;
        font-size: 26px;
        font-weight: 700;
        line-height: 38px;
        text-align: center;
    }


    .list_view_content {
        width: 700px;
        /* height: 120px; */
        padding: 0;
        /* padding-left: 50px; */
        /* padding-right: 50px; */
        /* overflow: hidden; */
        /* display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 20px;
        
        height: 200px;
        margin: 10px; */
    }

    .list_view_content_item {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .list_view_content_item>img {
        max-width: 160px;
        max-height: 94px;
        object-fit: contain;
    }

    .slick-dots {
        /* bottom: 20px; Change the position of the dots if needed */
    }

    .slick-dots li {
        margin: 0 1px;
        /* Increase or decrease this value for more/less space */
    }
}

@media (max-width: 700px) {

    .list_view_content {
        width: 550px;
        /* height: 120px; */
        padding: 0;
        /* overflow: hidden; */
        /* display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 20px;
        height: 200px;
        margin: 10px; */
    }
}


@media (max-width: 500px) {

    .list_view_content {
        width: 390px;
        /* height: 120px; */
        padding: 0;
        /* overflow: hidden; */
        /* display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 20px;
        height: 200px;
        margin: 10px; */
    }

    .list_view_header>h3 {
        max-width: 390px;
        height: 100px;
        font-family: Merriweather;
        font-size: 24px;
        font-weight: 700;
        line-height: 38px;
        text-align: center;
        color: var(--blue);
        padding-bottom: 50px;
    }

    .list_view_header {
        padding-bottom: 30px;
    }
}

@media (max-width: 500px) {

    .list_view_content {
        width: 320px;
        /* height: 120px; */
        padding: 0;
        /* overflow: hidden; */
        /* display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 20px;
        height: 200px;
        margin: 10px; */
    }

    .list_view_header>h3 {
        max-width: 390px;
        height: 100px;
        font-family: Merriweather;
        font-size: 24px;
        font-weight: 700;
        line-height: 38px;
        text-align: center;
        color: var(--blue);
        padding-bottom: 50px;
    }

    .list_view_header {
        padding-bottom: 30px;
    }
}