.card {
    cursor: pointer;
    max-width: 400px;
    height: 443px;
}

.card>a>img {
    max-width: 400px;
    height: 260px;
    object-fit: cover;
}

.card_body {
    height: 180px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #FFFFFF;
    padding: 20px, 24px, 24px, 24px;
}

.card_body>h5 {
    max-width: 352px;
    height: 60px;
    font-family: Inter;
    font-size: 20px;
    font-weight: 700;
    line-height: 30px;
    text-align: center;
    color: black;
}

.card_body>p {
    max-width: 352px;
    height: 65px;
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    text-align: center;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    color: var(--grey1);
}

.card:hover {
    box-shadow: 0px 0px 10px 0px #0000001A;
    -webkit-box-shadow: 0px 0px 10px 0px #0000001A;
    -moz-box-shadow: 0px 0px 10px 0px #0000001A;

}


@media (max-width: 1340px) {
    .card {
        max-width: 300px;
        height: 380px;
    }

    .card>a>img {
        max-width: 300px;
        height: 200px;
        object-fit: cover;
    }
}

@media (max-width: 1024px) {
    .card {
        max-width: 280px;
        height: 380px;
    }

    .card>a>img {
        max-width: 280px;
        height: 200px;
        object-fit: cover;
    }
}

@media (max-width: 821px) {
    .card {
        max-width: 240px;
        height: 380px;
    }

    .card>a>img {
        max-width: 240px;
        height: 200px;
        object-fit: cover;
    }
}


@media (max-width: 768px) {
    .card {
        max-width: 230px;
        height: 350px;
    }

    .card>a>img {
        max-width: 230px;
        height: 160px;
        object-fit: cover;
    }

    .card_body>h5 {
        max-width: 330px;
        height: 60px;
        font-family: Inter;
        font-size: 16px;
        font-weight: 700;
        line-height: 26px;
        text-align: center;
        color: black;
    }
    
    .card_body>p {
        max-width: 330px;
        height: 65px;
        font-family: Inter;
        font-size: 14px;
        line-height: 22px;
    }
}

@media (max-width: 500px) {
    .card {
        max-width: 300px;
        height: 365px;
    }

    .card>a>img {
        max-width: 300px;
        height: 200px;
        object-fit: cover;
    }

    .card_body>h5 {
        max-width: 330px;
        height: 60px;
        font-family: Inter;
        font-size: 16px;
        font-weight: 700;
        line-height: 26px;
        text-align: center;
        color: black;
    }
    
    .card_body>p {
        max-width: 330px;
        height: 65px;
        font-family: Inter;
        font-size: 14px;
        line-height: 22px;
    }
}