.diplom_view {
    width: 100%;
    min-height: 450px;
    background-color: var(--grey4);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    /* overflow: scroll; */
}

.diplom_view_body {
    max-width: 100%;
    min-width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 40px;
    margin: 10px;
}

.diplom_view_header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.diplom_view_header>h3 {
    max-width: 840px;
    height: 88px;
    font-family: Merriweather;
    font-size: 30px;
    font-weight: 700;
    line-height: 44px;
    text-align: center;
    color: var(--blue);

}

.diplom_view_content {
    width: 1280px;
    /* height: 120px; */
    padding: 0;
    /* overflow: hidden; */
    /* display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 20px;
    
    height: 200px;
    margin: 10px; */
}

.diplom_view_content_item {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.diplom_view_content_item>img {
    max-width: 176px;
    max-height: 252px;
    object-fit: contain;
}

.slick-dots li button:before {
    color: var(--grey2);
    /* Change to any color you like */
    font-size: 12px;
    /* You can adjust the size of the dots if needed */
}

/* Change the color of the active dot */
.slick-dots li.slick-active button:before {
    color: var(--blue);
    /* Change to the color you want for the active dot */
}

.slick-track {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    /* gap: 5px; */
}

.slick-prev {
    display: none !important;
}

.slick-next {
    display: none !important;
}

@media (max-width: 1300px) {

    .diplom_view_content {
        width: 1100px;
        /* height: 120px; */
        padding: 0;
        /* padding-left: 50px; */
        /* padding-right: 50px; */
        /* overflow: hidden; */
        /* display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 20px;
        
        height: 200px;
        margin: 10px; */
    }

    .diplom_view_content_item {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .diplom_view_content_item>img {
        max-width: 140px;
        max-height: 200px;
        object-fit: contain;
    }

}

@media (max-width: 1024px) {

    .diplom_view_content {
        width: 900px;
        /* height: 120px; */
        padding: 0;
        /* padding-left: 50px; */
        /* padding-right: 50px; */
        /* overflow: hidden; */
        /* display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 20px;
        
        height: 200px;
        margin: 10px; */
    }

    .diplom_view_content_item {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .diplom_view_content_item>img {
        max-width: 140px;
        max-height: 200px;
        object-fit: contain;
    }

}


@media (max-width: 1000px) {
    .diplom_view_header>h3 {
        font-family: Merriweather;
        font-size: 26px;
        font-weight: 700;
        line-height: 38px;
        text-align: center;


    }


    .diplom_view_content {
        width: 700px;
        /* height: 120px; */
        padding: 0;
        /* padding-left: 50px; */
        /* padding-right: 50px; */
        /* overflow: hidden; */
        /* display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 20px;
        
        height: 200px;
        margin: 10px; */
    }

    .diplom_view_content_item {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .diplom_view_content_item>img {
        max-width: 140px;
        max-height: 200px;
        object-fit: contain;
    }

    .slick-dots {
        /* bottom: 20px; Change the position of the dots if needed */
    }

    .slick-dots li {
        margin: 0 1px;
        /* Increase or decrease this value for more/less space */
    }
}

@media (max-width: 700px) {

    .diplom_view_content {
        width: 550px;
        /* height: 120px; */
        padding: 0;
        /* overflow: hidden; */
        /* display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 20px;
        height: 200px;
        margin: 10px; */
    }
}

@media (max-width: 500px) {

    .diplom_view_content {
        width: 390px;
        /* height: 120px; */
        padding: 0;
        /* overflow: hidden; */
        /* display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 20px;
        height: 200px;
        margin: 10px; */
    }

    .diplom_view_header>h3 {
        max-width: 390px;
        height: 100px;
        font-family: Merriweather;
        font-size: 24px;
        font-weight: 700;
        line-height: 38px;
        text-align: center;
        color: var(--blue);
    
    }
}

@media (max-width: 450px) {

    .diplom_view_content {
        width: 300px;
        /* height: 120px; */
        padding: 0;
        /* overflow: hidden; */
        /* display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 20px;
        height: 200px;
        margin: 10px; */
    }

    .diplom_view_header>h3 {
        max-width: 390px;
        height: 100px;
        font-family: Merriweather;
        font-size: 24px;
        font-weight: 700;
        line-height: 38px;
        text-align: center;
        color: var(--blue);
    
    }
}