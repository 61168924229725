/* Full-screen container for the 404 page */
.not-found-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f9f9f9;
    text-align: center;
}

/* Main content of the 404 page */
.not-found-content {
    max-width: 500px;
    padding: 20px;
    border-radius: 10px;
    background-color: #fff;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

/* Large 404 title */
.not-found-title {
    font-size: 6rem;
    color: #ff4c4c;
    margin-bottom: 20px;
}

/* Text message */
.not-found-text {
    font-size: 1.5rem;
    color: #333;
    margin-bottom: 30px;
}

/* Button to navigate back home */
.not-found-button {
    padding: 10px 20px;
    font-size: 1rem;
    color: #fff;
    background-color: #007bff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

/* Button hover effect */
.not-found-button:hover {
    background-color: #0056b3;
}
