.image_view {
    width: 100%;
    height: auto;
    margin-bottom: -7px;
}


.image_view_content {
    width: 100%;
    padding: 0;
}

.image_view_content_item {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.image_view_content_item>img {
    width: 288px;
    height: 288px;
    /* object-fit: contain; */
}



.slick-dots li button:before {
    color: var(--grey2);
    /* Change to any color you like */
    font-size: 12px;
    /* You can adjust the size of the dots if needed */
}

/* Change the color of the active dot */
.slick-dots li.slick-active button:before {
    color: var(--blue);
    /* Change to the color you want for the active dot */
}

.slick-track {
    display: flex;
    flex-direction: row;
    /* justify-content: space-between; */
    /* align-items: center; */
    /* gap: 5px; */
}

.slick-prev {
    display: none !important;
}

.slick-next {
    display: none !important;
}

/* @media (max-width: 1024px) {
    .image_view_content_item>img {
        width: 240px;
        height: 240px;
        object-fit: contain;
    }
} */

@media (max-width: 1024px) {
    .image_view {
        /* margin-bottom: -10px; */
    }
}


@media (max-width: 1000px) {
    .image_view {
        /* margin-bottom: -90px; */
    }
    .image_view_content {
        /* width: 700px; */
        /* height: 120px; */
        padding: 0;
        /* padding-left: 50px; */
        /* padding-right: 50px; */
        /* overflow: hidden; */
        /* display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 20px;
        
        height: 200px;
        margin: 10px; */
    }

    .image_view_content_item {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .image_view_content_item>img {
        width: 248px;
        height: 248px;
        /* object-fit: contain; */
    }

    .slick-dots {
        /* bottom: 20px; Change the position of the dots if needed */
    }

    .slick-dots li {
        margin: 0 1px;
        /* Increase or decrease this value for more/less space */
    }
}

@media (max-width: 700px) {
    .image_view {
        /* margin-bottom: -120px; */
    }
    .image_view_content {
        /* width: 550px; */
        /* height: 120px; */
        padding: 0;
        /* overflow: hidden; */
        /* display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 20px;
        height: 200px;
        margin: 10px; */
    }
}