
.image_list {
    max-width: 1440px;
    height: 100%;
    overflow: hidden !important;
    
}

.image_list_body {
    display: flex;
    flex-direction: row;
    /* flex-wrap: nowrap; */
    transition: transform 0.3s ease;
}

.image_list svg {
    color: var(--white);
}


.image-item {
    cursor: pointer;
    position: relative;
    width: 288px;
    height: 288px;
}

.image-item_image {
    display: block;
    width: 100%;
    height: auto;
}

.image-item_overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: .5s ease;
    background-color: #103F7BCC;
}

.image-item:hover .image-item_overlay {
    opacity: 1;
}

.image-item_text {
    color: white;
    font-size: 20px;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    text-align: center;
}

.image-item_text svg {
    width: 90px;
    height: 90px;
}

@media (max-width: 1280px) {
    .image-item {
        cursor: pointer;
        position: relative;
        width: 260px;
        height: 260px;
    } 
    
}

@media (max-width: 1024px) {
    .image-item {
        cursor: pointer;
        position: relative;
        width: 205px;
        height: 205px;
    } 
    
}


@media (max-width: 1000px) {
    .image-item {
        cursor: pointer;
        position: relative;
        width: 250px;
        height: 250px;
    } 
}

@media (max-width: 900px) {
    .image-item {
        cursor: pointer;
        position: relative;
        width: 205px;
        height: 205px;
    } 
}

@media (max-width: 700px) {
    .image-item {
        cursor: pointer;
        position: relative;
        width: 175px;
        height: 175px;
    } 

}

@media (max-width: 600px) {
    .image-item {
        cursor: pointer;
        position: relative;
        width: 175px;
        height: 175px;
    } 

}

@media (max-width: 450px) {
    .image-item {
        cursor: pointer;
        position: relative;
        width: 225px;
        height: 225px;
    } 

}


@media (max-width: 400px) {
    .image-item {
        cursor: pointer;
        position: relative;
        width: 180px;
        height: 180px;
    } 

}